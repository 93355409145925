import { Injectable } from '@angular/core';
import { SessionDataService, SessionStore } from '../session/state';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private authStore: SessionStore,
    private authDataService: SessionDataService
  ) { }

  logIn(email: string, password: string, twoFactorIdentifier?: string, twoFactorCode?: string): any {
    return this.authDataService.logIn(email, password, twoFactorIdentifier, twoFactorCode).pipe(
      tap(session => {
        // @ts-ignore
        this.authStore.login(session);
      })
    );
  }

  logInLinkedIn(code: string, rot: string): any {
    return this.authDataService.logInLinkedIn(code, rot).pipe(
      tap(session => {
        // @ts-ignore
        this.authStore.login(session);
      })
    );
  }

  // tslint:disable-next-line:variable-name
  register(email: string, password: string, password_confirmation: string, privacyPolicy: boolean, givenName: string, familyName: string, company: string, country: string, language: string, timezone: string, sapConnectionType: string, phone?: string, position?: string): any {
    return this.authDataService.register(email, password, password_confirmation, privacyPolicy, givenName, familyName, company, country, language, timezone, sapConnectionType, phone, position).pipe(
      tap(session => {
        // @ts-ignore
        this.authStore.login(session);
      })
    );
  }

  forgotPassword(email: string): any {
    return this.authDataService.forgotPassword(email).pipe(
      tap(() => {
      })
    );
  }

  // tslint:disable-next-line:variable-name
  resetPassword(email: string, expires: number, token: string, password: string, password_confirmation: string): any {
    return this.authDataService.resetPassword(email, expires, token, password, password_confirmation).pipe(
      tap(session => {
        // @ts-ignore
        // this.authStore.login(session);
      })
    );
  }

  logout(): any {
    return this.authDataService.logout();
  }
}
