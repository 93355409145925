import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageStore } from '../../store/language/language.store';
import { LanguageQuery } from '../../store/language/language.query';
import { TranslocoService } from '@ngneat/transloco';
import { faLanguage } from '@fortawesome/pro-regular-svg-icons';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-language-switcher',
	templateUrl: './language-switcher.component.html',
	styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit, OnDestroy {
	faLanguage = faLanguage;

	public activeLanguage: string;

	private subscription!: Subscription;

	constructor(
		private languageStore: LanguageStore,
		private languageQuery: LanguageQuery,
		private translocoService: TranslocoService,
		private translocoLocaleService: TranslocoLocaleService
	) {}

	ngOnInit(): void {
		this.initLanguage();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initLanguage(): void {
		this.languageQuery.select().subscribe((res) => {
			if (res.lang === 'de') {
				this.activeLanguage = 'Deutsch';
				this.translocoLocaleService.setLocale('de-DE');
				localStorage.setItem('infoday_locale', 'de-DE');
			} else if (res.lang === 'fr') {
				this.activeLanguage = 'Deutsch';
				this.translocoLocaleService.setLocale('fr-FR');
				localStorage.setItem('infoday_locale', 'fr-FR');
			} else if (res.lang === 'es') {
				this.activeLanguage = 'Deutsch';
				this.translocoLocaleService.setLocale('es-ES');
				localStorage.setItem('infoday_locale', 'es-ES');
			} else {
				this.activeLanguage = 'English';
				this.translocoLocaleService.setLocale('en-US');
				localStorage.setItem('infoday_locale', 'en-US');
			}

			this.translocoService.setActiveLang(res.lang);
			localStorage.setItem('infoday_language', res.lang);
		});
	}

	select(language: string): void {
		this.languageStore.update({ lang: language });
	}
}
