import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
	public logoUrl: string;

	constructor() {}

	ngOnInit(): void {
		this.logoUrl = 'assets/logo/id-logo-et.svg';
	}

	test(): void {}
}
