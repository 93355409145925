import { Component, Input, OnInit } from '@angular/core';
import SwiperCore, { SwiperOptions, Autoplay } from 'swiper';
SwiperCore.use([Autoplay]);

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss']
})
export class PartnerListComponent implements OnInit {

  @Input() partners: Array<any>;
  @Input() conf: number;
  @Input() page: string;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    autoplay: {
      delay: 2000,
    },
    breakpoints: {
      480: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 50,
      },
    },
  };

  constructor() { }

  ngOnInit(): void {
  }

  // Performance improvement
  trackByFn(index, item): number {
    return index;
  }

}
