import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-dialog-adblocker',
  templateUrl: './dialog-adBlocker.component.html',
  styleUrls: ['./dialog-adBlocker.component.scss']
})
export class DialogAdBlockerComponent implements OnInit {

  private browserLanguage: string;

  public logoUrl: string;
  public headline: string;
  public btn: string;
  public browserText: string;

  faClose = faTimes;

  constructor(
    public dialogRef: MatDialogRef<DialogAdBlockerComponent>,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.initLanguage();
    this.logoUrl = 'assets/logo/espresso-tutorials-logo.png';
  }

  onClose(): void {
    this.dialogRef.close();
  }

  initLanguage(): void {
    this.browserLanguage = this.translate.getActiveLang();
    if (this.browserLanguage === 'de') {
      this.headline = 'Information';
      this.btn = 'Verstanden';
      this.browserText = 'Wir haben einen aktiven AdBlocker erkannt. Um unsere Plattform vollumfänglich nutzen zu können, möchten wir Sie bitten, den AdBlocker für die Seite https://infoday.io zu deaktivieren.';
    }  else {
      this.headline = 'Information';
      this.btn = 'OK';
      this.browserText = 'We have detected an active AdBlocker. To take full advantage of our platform, we ask you to disable the AdBlocker for the domain https://infoday.io.';
    }
  }

}
