import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SessionQuery, SessionStore } from '../session/state';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  constructor(
    private injector: Injector,
    private sessionQuery: SessionQuery,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: any = this.sessionQuery.getToken();
    /*
    if (!request.headers.has('Content-Type')) {
      request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});
    } else {
      request = request.clone({headers: request.headers.set('Content-Type', 'multipart/form-data')});
    }
     */

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
    return next.handle(request);
  }

}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authStore: SessionStore,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((response: any) => {
          if (response instanceof HttpErrorResponse && response.status === 401) {
            this.authStore.logout();
            this.router.navigateByUrl('').then();
          }
          return throwError(response);
        })
      );
  }
}

