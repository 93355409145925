import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

import { Integrations } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.production ? 'https://6bedc121cb434d59aefeaa380ade8f4d@o172773.ingest.sentry.io/5696420' : '',
  environment: environment.production ? 'prod' : 'dev',
  release: 'infoday@' + environment.VERSION,
  ignoreErrors: [
    'Non-Error exception captured'
  ],
  denyUrls: [
    // Arena chat
    /api\.arena\.im/i,
    /https?:\/\/((cdn|www|api|go)\.)?arena\.im/,
    /https?:\/\/((cdn|www|firestore)\.)?googleapis\.com/,
    /https?:\/\/((cdn|www|e)\.)?clarity\.ms/
  ],
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'staging.infoday.io', 'infoday.io', environment.API_URL],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.TRACING,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
