import { BrowserModule } from '@angular/platform-browser';
import {
	APP_INITIALIZER,
	CUSTOM_ELEMENTS_SCHEMA,
	ErrorHandler,
	Injectable,
	NgModule,
	NO_ERRORS_SCHEMA,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import {
	HTTP_INTERCEPTORS,
	HttpClientJsonpModule,
	HttpClientModule,
} from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/global/shared.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';
import { TranslocoService } from '@ngneat/transloco';

export function preloadUser(transloco: TranslocoService): any {
	return () => {
		let lang = localStorage.getItem('infoday_language');
		let locale = localStorage.getItem('infoday_locale');
		if (!lang) {
			// @ts-ignore
			const navlang = navigator.language || navigator.userLanguage;
			if (
				navlang === 'de-DE' ||
				navlang === 'de-CH' ||
				navlang === 'de-AT' ||
				navlang === 'de'
			) {
				lang = 'de';
				locale = 'de-DE';
			} else if (navlang === 'fr-FR') {
				lang = 'fr';
				locale = 'fr-FR';
			} else if (navlang === 'es-ES') {
				lang = 'es';
				locale = 'es-ES';
			} else {
				lang = 'en';
				locale = 'en-US';
			}
		}
		transloco.setActiveLang(lang);
		localStorage.setItem('infoday_language', lang);
		localStorage.setItem('infoday_locale', locale);
		return transloco.load(lang).toPromise();
	};
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		environment.production ? [] : AkitaNgDevtools.forRoot(),
		AkitaNgRouterStoreModule,
		HttpClientModule,
		HttpClientJsonpModule,
		TranslocoRootModule,
		TranslocoLocaleModule.init({
			langToLocaleMapping: {
				en: 'en-US',
				de: 'de-DE',
			},
		}),
		FontAwesomeModule,
		SharedModule,
		ScrollToModule.forRoot(),
	],
	providers: [
		{
			provide: NG_ENTITY_SERVICE_CONFIG,
			useValue: {
				baseUrl: 'https://jsonplaceholder.typicode.com',
			},
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true,
		},
		/*
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
     */
		{
			provide: APP_INITIALIZER,
			useFactory: preloadUser,
			deps: [TranslocoService, Sentry.TraceService],
			multi: true,
		},
	],
	exports: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
	bootstrap: [AppComponent],
})
export class AppModule {}
