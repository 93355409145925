import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/session/session-guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
	},
	{
		path: 'faq',
		loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqModule),
	},
	{
		path: 'imprint',
		loadChildren: () => import('./pages/imprint/imprint.module').then((m) => m.ImprintModule),
	},
	{
		path: 'privacy',
		loadChildren: () => import('./pages/privacy/privacy.module').then((m) => m.PrivacyModule),
	},
	{
		path: 'register',
		loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterModule),
	},
	{
		path: 'register/:code',
		loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterModule),
	},
	{
		path: 'debug',
		loadChildren: () => import('./pages/debug/debug.module').then((m) => m.DebugModule),
	},
	{
		path: 'forgot-password',
		loadChildren: () => import('./pages/user/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
	},
	{
		path: 'reset-password',
		loadChildren: () => import('./pages/user/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'login/:code',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'profile',
		loadChildren: () => import('./pages/user/profile/profile.module').then((m) => m.ProfileModule),
		canActivate: [AuthGuard],
		data: {
			complete: true,
		},
	},
	{
		path: 'profile/:id/edit',
		loadChildren: () => import('./pages/user/profile/edit-profile/edit-profile.module').then((m) => m.EditProfileModule),
		canActivate: [AuthGuard],
		data: {
			complete: true,
		},
	},
	{
		path: 'profile/:id/edit/:complete',
		loadChildren: () => import('./pages/user/profile/edit-profile/edit-profile.module').then((m) => m.EditProfileModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'conference/:id',
		loadChildren: () => import('./pages/conference/conference.module').then((m) => m.ConferenceModule),
		canActivate: [AuthGuard],
		data: {
			complete: true,
		},
	},
	{
		path: 'conference/:conf/talk/:id/:slug',
		loadChildren: () => import('./pages/conference/detail/detail.module').then((m) => m.DetailModule),
		canActivate: [AuthGuard],
		data: {
			complete: true,
		},
	},
	{
		path: 'conference/:conf/partner/:id/:page/:slug',
		loadChildren: () => import('./pages/conference/partner-detail/partner-detail.module').then((m) => m.PartnerDetailModule),
		canActivate: [AuthGuard],
		data: {
			complete: true,
		},
	},
	{
		path: 'conference/:conf/author/:id/:slug',
		loadChildren: () => import('./pages/conference/author/author.module').then((m) => m.AuthorModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'conference/:conf/author/:id/:slug/:session/:page',
		loadChildren: () => import('./pages/conference/author/author.module').then((m) => m.AuthorModule),
		canActivate: [AuthGuard],
		data: {
			complete: true,
		},
	},
	{
		path: 'conference/:conf/rating/:session/:page',
		loadChildren: () => import('./pages/conference/rating/rating.module').then((m) => m.RatingModule),
		canActivate: [AuthGuard],
		data: {
			complete: true,
		},
	},
	{
		path: 'conference/:conf/rating/:session/:page/:rating',
		loadChildren: () => import('./pages/conference/rating/rating.module').then((m) => m.RatingModule),
		canActivate: [AuthGuard],
		data: {
			complete: true,
		},
	},
	{
		path: 'two-factor/:twoFactorIdentifier',
		loadChildren: () => import('./pages/two-factor/two-factor.module').then((m) => m.TwoFactorModule),
	},
	{
		path: '**',
		loadChildren: () => import('./pages/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'top',
			relativeLinkResolution: 'legacy',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
