import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Session } from '../../interfaces/session';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {

  private apiUrl = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  logIn(email: string, password: string, twoFactorIdentifier?: string, twoFactorCode?: string): Observable<Session> {
    const url = `${this.apiUrl}auth/login?include=image`;
    return this.http.post<any>(url, { email, password, platform: 'infoday', twoFactorIdentifier, twoFactorCode });
  }

  // tslint:disable-next-line:variable-name
  register(email: string, password: string, password_confirmation: string, privacyPolicy: boolean, givenName: string, familyName: string, company: string, country: string, language: string, timezone: string, sapConnectionType: string, phone?: string, position?: string): Observable<Session> {
    const url = `${this.apiUrl}auth/register`;
    const body = {
      email,
      password,
      password_confirmation,
      privacyPolicy,
      givenName,
      familyName,
      company,
      country,
      language,
      timezone,
      sapConnectionType
    };

    if (phone) {
      // @ts-ignore
      body.phone = phone;
    }

    if (phone) {
      // @ts-ignore
      body.position = position;
    }

    return this.http.post<any>(url, body);
  }

  logInLinkedIn(code: string, rot: string): Observable<Session> {
    const url = `${this.apiUrl}auth/provider/linkedin/callback?code=${code}&rot=${rot}`;
    return this.http.get<any>(url);
  }

  logout(): Observable<any> {
    const url = `${this.apiUrl}auth/logout`;
    return this.http.post<any>(url, '');
  }

  forgotPassword(email: string): Observable<any> {
    const url = `${this.apiUrl}auth/forgot-password`;
    return this.http.post<any>(url, { email, platform: 'infoday' });
  }

  // tslint:disable-next-line:variable-name
  resetPassword(email: string, expires: number, token: string, password: string, password_confirmation: string): Observable<Session> {
    const url = `${this.apiUrl}auth/reset-password`;
    const body = {
      email,
      expires,
      token,
      password,
      password_confirmation
    };

    return this.http.post<any>(url, body);
  }
}
