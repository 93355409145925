import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountdownService {

  public dDay = new Date('Nov 30 2021 10:19:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  constructor() { }

  getTimeDifference(date?: string): any {
    this.dDay = new Date(date);
    return this.dDay.getTime() - new Date().getTime();
  }

  getEnded(date?: string): any {
    this.dDay = new Date(date);
    return this.dDay.getTime() + 5 * 60000 < new Date().getTime();
  }


  allocateTimeUnits(timeDifference): any {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));

    const timer = {
      seconds: this.secondsToDday,
      minutes: this.minutesToDday,
      hours: this.hoursToDday,
      days: this.daysToDday,
    };

    return timer;
  }
}
