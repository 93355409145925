import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SuccessHandlerService {

  constructor(
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  // Success Handler
  successHandler(message: any, type?: string, redirect?: string): void {
    this.openSnackBar(message);

    if (redirect) {
      this.router.navigateByUrl(redirect).then();
    }
  }

  // Display Snackbar
  openSnackBar(message: string): any {
    this.snackBar.open(message, 'OK', {
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }
}
