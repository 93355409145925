import { Injectable } from '@angular/core';
import { SessionStore } from './session.store';
import { SessionDataService } from './session-data.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private authStore: SessionStore,
    private authDataService: SessionDataService
  ) { }

  login(creds): any {
    // @ts-ignore
    return this.authDataService.login(creds).pipe(tap((session) => this.authStore.login(session)));
  }

  logout(): any {
    this.authStore.logout();
  }
}
