import { Component, OnInit } from '@angular/core';
import { faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { format } from 'date-fns';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public actualYear: string;
  faFacebook = faFacebookSquare;
  faTwitter = faTwitterSquare;
  faInstagram = faInstagramSquare;
  faLinkedIn = faLinkedin;
  faYoutube = faYoutubeSquare;

  constructor(
  ) {}

  ngOnInit(): void {
    this.actualYear = format(new Date(), 'yyyy');
  }

}
