import { Pipe, PipeTransform } from '@angular/core';
import { CountdownService } from '../services/countdown.service';

@Pipe({
  name: 'sessionIsOver'
})
export class SessionIsOverPipe implements PipeTransform {

  constructor(
    private countdownService: CountdownService
  ) {
  }

  transform(value: string, ...args: unknown[]): any {

    let list = 'coming';
    if (this.countdownService.getTimeDifference(value) <= 0) {
      list = 'opacity-25 over';
    } else {
      list = 'coming';
    }

    setInterval(() => {
      if (this.countdownService.getTimeDifference(value) <= 0) {
        list = 'opacity-25 over';
      } else {
        list = 'coming';
      }
    }, 1000);

    return list;
  }

}
