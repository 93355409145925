<ng-container *transloco="let t">
  <div class="bg-white border-b border-solid border-gray-400 w-full p-4 flex flex-row flex-wrap justify-between items-center">
    <app-logo></app-logo>
    <div class="flex flex-row flex-wrap items-center">
      <app-language-switcher></app-language-switcher>
      <ng-container *showIfLoggedIn="true">
        <app-profile-button></app-profile-button>
      </ng-container>
      <a *showIfLoggedIn="false" class="text-gray-700 hover:text-orange-500 text-sm ml-4" routerLink="/register" title="{{t('UI.register_now')}}">{{t('UI.register')}}</a>
      <button *showIfLoggedIn="false" class="appearance-none rounded-md bg-blue-500 text-white text-sm font-medium py-2 px-4 md:px-8 ml-4" (click)="goLogin()" title="{{t('UI.login_now')}}">
        <fa-icon class="block md:hidden" [icon]="faSignIn"></fa-icon>
        <span class="hidden md:block">{{t('UI.login')}}</span>
      </button>
      <button *showIfLoggedIn="true" class="appearance-none rounded-md bg-blue-500 text-white text-sm font-medium py-2 px-4 md:px-8 ml-4" (click)="logout()">
        <fa-icon class="block md:hidden" [icon]="faSignOut"></fa-icon>
        <span class="hidden md:block">{{t('UI.logout')}}</span>
      </button>
    </div>
  </div>
</ng-container>
