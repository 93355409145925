import { Pipe, PipeTransform } from '@angular/core';
import slugify from 'slugify';

@Pipe({
  name: 'slugify'
})
export class SlugifyPipe implements PipeTransform {

  transform(value: string): string {
    const slug = value.replace('/', '');
    return slugify(slug.toLowerCase(), { lower: true, remove: /[*+~.()'"!:@/]/g });
  }

}
