<ng-container *transloco="let t">
<div class="px-4 mt-2">
  <ng-container *ngIf="partners && partners.length > 6; else singlePartner">
    <swiper
      [config]="config"
    >
      <ng-template swiperSlide *ngFor="let partner of partners; trackBy: trackByFn">
        <ng-container *ngIf="partner.id === 13; else extern">
          <a class="block h-logos flex items-center justify-center opacity-50 hover:opacity-100 transition-all ease-in-out duration-200 partner" target="_blank" href="https://www.xiting.de/" [title]="t('UI.visit_sponsors_label') + partner.name">
            <img class="w-full h-full object-center" [src]="partner.logo?.url" [alt]="partner.name">
          </a>
        </ng-container>
        <ng-template #extern>
          <a class="block h-logos flex items-center justify-center opacity-50 hover:opacity-100 transition-all ease-in-out duration-200 partner" routerLink="/conference/{{conf}}/partner/{{partner.id}}/{{page}}/{{partner.name | slugify}}" [title]="t('UI.visit_sponsors_label') + partner.name">
            <img class="w-full h-full object-center" [src]="partner.logo?.url" [alt]="partner.name">
          </a>
        </ng-template>
      </ng-template>
    </swiper>
  </ng-container>
  <ng-template #singlePartner>
    <div class="flex flex-row flex-wrap justify-center items-center">
      <div class="w-1/3 md:w-1/6 px-2 md:px-4" *ngFor="let partner of partners; trackBy: trackByFn">
        <ng-container *ngIf="partner.id === 13; else extern">
          <a class="block opacity-50 hover:opacity-100 transition-all ease-in-out duration-200" target="_blank" href="https://www.xiting.de/" [title]="t('UI.visit_sponsors_label') + partner.name">
            <img class="block mx-auto w-full h-auto" [src]="partner.logo?.url" [alt]="partner.name">
          </a>
        </ng-container>
        <ng-template #extern>
          <a class="block opacity-50 hover:opacity-100 transition-all ease-in-out duration-200" routerLink="/conference/{{conf}}/partner/{{partner.id}}/{{page}}/{{partner.name | slugify}}" [title]="t('UI.visit_sponsors_label') + partner.name">
            <img class="block mx-auto w-full h-auto" [src]="partner.logo?.url" [alt]="partner.name">
          </a>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
</ng-container>
