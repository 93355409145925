import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import * as storage from '../storage';
import { MatDialog } from '@angular/material/dialog';

export type SessionState = {
  accessToken: string;
  roles: string;
  familyName: string;
  givenName: string;
  uuid: number;
  timezone: string,
  image: string,
  company: string,
  expiresAt: string,
  complete: boolean,
  // role: string;
};

export function createInitialSessionState(): SessionState {
  return {
    accessToken: null,
    roles: null,
    familyName: null,
    givenName: null,
    uuid: null,
    timezone: null,
    image: null,
    company: null,
    expiresAt: null,
    complete: null,
    // role: null,
    ...storage.getSession(),
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sessionInfoday' })
export class SessionStore extends Store<SessionState> {
  public dialog: MatDialog;

  constructor() {
      super(
        createInitialSessionState()
      );
  }

  login(session: any): any  {
    // console.log(session);
    const accessToken = session.meta.accessToken;
    const roles = session.data.roles;
    const familyName = session.data.familyName;
    const givenName = session.data.givenName;
    const uuid = session.data.id;
    const timezone = session.data.timezone;
    const image = session.data.image?.url ? session.data.image?.url : '';
    const expiresAt = session.meta.expiresAt;

    const company = session.data.company;
    const sapConnectionType = session.data.sapConnectionType;

    let complete = true;
    if (!company || !sapConnectionType) {
      complete = false;
    }

    const creds = {
      accessToken,
      roles,
      familyName,
      givenName,
      uuid,
      timezone,
      company,
      image,
      expiresAt,
      complete
    };
    this.update(creds);
    storage.saveSession(creds);
  }

  logout(): any {
    storage.clearSession();
    this.update(createInitialSessionState());
  }
}
