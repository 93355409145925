import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { SessionQuery } from './state';
import { Observable } from 'rxjs';
import * as storage from '../../shared/session/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private sessionQuery: SessionQuery
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    const roles = this.sessionQuery.hasRoles();
    const creds = storage.getSession();

    if (this.sessionQuery.isLoggedIn()) {
      if (next.data.complete) {

        let isComplete = false;
        if ( next.data.complete === creds.complete) {
          isComplete = true;
        }

        if (isComplete) {
          return true;
        } else {
          this.router.navigateByUrl('/profile/12345/edit/complete').then();
          return true;
        }
      } else {
        return true;
      }
      return true;
    } else {
      this.router.navigateByUrl('login').then();
      return false;
    }
  }
}
