<ng-container *transloco="let t">
<a routerLink="/profile" [title]="t('UI.visit_profile')" class="appearance-none w-8 h-8 rounded-full flex justify-center items-end overflow-hidden bg-gray-400 text-gray-650 text-3xl ml-4">
  <ng-container *ngIf="imageUrl; else noImage">
    <img class="w-8 h-8 rounded-full object-center object-cover" [src]="imageUrl">
  </ng-container>
  <ng-template #noImage>
    <fa-icon class="-mb-2" [icon]="faUser"></fa-icon>
  </ng-template>
</a>
</ng-container>
