import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageStore } from './shared/store/language/language.store';
import { SessionQuery, SessionStore } from './shared/session/state';
import { CountdownService } from './shared/services/countdown.service';
import { AdBlockerService } from './shared/services/ad-blocker.service';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	private browserLanguage: string;
	title = 'infoday.io';

	constructor(
		private translocoService: TranslocoService,
		private translocoLocaleService: TranslocoLocaleService,
		private languageStore: LanguageStore,
		private sessionQuery: SessionQuery,
		private countdownService: CountdownService,
		private authStore: SessionStore,
		private adBlockerService: AdBlockerService
	) {
		this.initializeApp();
	}

	initializeApp(): void {
		let savedLang = localStorage.getItem('infoday_language');
		let savedLocale = localStorage.getItem('infoday_locale');

		if (savedLang) {
			if (savedLang !== 'de' && savedLang !== 'en') {
				savedLang = 'en';
			}
			this.browserLanguage = savedLang;
			this.translocoService.setActiveLang(savedLang);
			this.languageStore.update({ lang: this.browserLanguage });
			localStorage.setItem('infoday_language', this.browserLanguage);

			if (
				savedLocale !== 'de-DE' &&
				savedLocale !== 'en-US' &&
				savedLocale !== 'fr-FR' &&
				savedLocale !== 'es-ES'
			) {
				savedLocale = 'en-US';
			}
			if (savedLocale) {
				this.translocoLocaleService.setLocale(savedLocale);
			}
			localStorage.setItem('infoday_locale', savedLocale);
		} else {
			// @ts-ignore
			if (
				'de-DE' === navigator.language ||
				// @ts-ignore
				'de-DE' === navigator.userLanguage
			) {
				this.browserLanguage = 'de';
				this.translocoService.setActiveLang('de');
				this.translocoLocaleService.setLocale('de-DE');
				this.languageStore.update({ lang: this.browserLanguage });
				localStorage.setItem('infoday_language', this.browserLanguage);
				localStorage.setItem('infoday_locale', 'de-DE');
				// @ts-ignore
			} else if (
				'fr-FR' === navigator.language ||
				// @ts-ignore
				'fr-FR' === navigator.userLanguage
			) {
				this.browserLanguage = 'fr';
				this.translocoService.setActiveLang('fr');
				this.translocoLocaleService.setLocale('fr-FR');
				this.languageStore.update({ lang: this.browserLanguage });
				localStorage.setItem('infoday_language', this.browserLanguage);
				localStorage.setItem('infoday_locale', 'fr-FR');
				// @ts-ignore
			} else if (
				'es-ES' === navigator.language ||
				// @ts-ignore
				'es-ES' === navigator.userLanguage
			) {
				this.browserLanguage = 'es';
				this.translocoService.setActiveLang('es');
				this.translocoLocaleService.setLocale('es-ES');
				this.languageStore.update({ lang: this.browserLanguage });
				localStorage.setItem('infoday_language', this.browserLanguage);
				localStorage.setItem('infoday_locale', 'es-ES');
			} else {
				this.translocoLocaleService.setLocale('en-US');
				this.browserLanguage = this.translocoService.getDefaultLang();
				this.languageStore.update({ lang: this.browserLanguage });
				localStorage.setItem('infoday_language', this.browserLanguage);
				localStorage.setItem('infoday_locale', 'en-US');
			}
		}

		this.adBlockerService.checkAdBlocker();

		this.checkIfSessionExpired();
	}

	checkIfSessionExpired(): void {
		const expires = this.sessionQuery.getExpire();

		if (!expires) {
			return;
		}

		if (this.countdownService.getTimeDifference(expires) <= 0) {
			this.authStore.logout();
		}
	}
}
