import { Pipe, PipeTransform } from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';

@Pipe({
  name: 'sapConnectionType'
})
export class SapConnectionTypePipe implements PipeTransform {

  constructor(
    private translate: TranslocoService
  ) {
  }

  transform(value: unknown, ...args: unknown[]): unknown {

    switch (value) {
      case 'customer':
        return this.translate.translate('UI.customer');
      case 'partner':
        return this.translate.translate('UI.partner');
      case 'freelancer':
        return this.translate.translate('UI.freelancer');
      case 'university':
        return this.translate.translate('UI.university');
      default:
        return this.translate.translate('UI.other');
    }

  }

}
