import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { SessionStore, SessionState } from './session.store';

@Injectable({
  providedIn: 'root'
})
export class SessionQuery extends Query<SessionState> {
  isLoggedIn$ = this.select((state) => toBoolean(state.accessToken));
  isComplete$ = this.select((state) => toBoolean(state.complete));
  roles$ = this.select((state) => state.roles);

  constructor(protected store: SessionStore) {
    super(store);
  }

  isLoggedIn(): any {
    return toBoolean(this.getValue().accessToken);
  }

  getToken(): any {
    return this.getValue().accessToken;
  }

  getUuid(): any {
    return this.getValue().uuid;
  }

  getName(): any {
    return `${this.getValue().givenName} ${this.getValue().familyName}`;
  }

  getFamilyName(): any {
    return this.getValue().familyName;
  }

  getGivenName(): any {
    return this.getValue().givenName;
  }

  getImage(): any {
    return this.getValue().image;
  }

  getCompany(): any {
    return this.getValue().company;
  }

  getTimezone(): any {
    return this.getValue().timezone;
  }

  hasRoles(): any {
    return this.getValue().roles;
  }

  getExpire(): any {
    return this.getValue().expiresAt;
  }

  getComplete(): any {
    return this.getValue().complete;
  }

}
