<ng-container *transloco="let t">
  <footer class="bg-gray-300 pt-8">

    <div class="w-full border-solid border-b-4 border-blue-500">

      <div class="w-full max-w-content mx-auto px-4 md:px-12">

        <hr class="block bg-gray-600 h-px w-full m-0 border-0">
        <div class="flex flex-row flex-wrap justify-start py-12 px-4">
          <div class="w-full sm:w-1/2 md:w-1/5 text-center sm:text-left mb-4 md:mb-0">
            <h2 class="text-sm uppercase text-gray-700 font-semibold mb-2">{{t('UI.legals')}}</h2>
            <nav>
              <ul class="list-none p-0 text-center sm:text-left">
                <li class="block mb-2">
                  <a class="text-sm no-underline text-gray-800 hover:text-blue-500" href="{{ t('LINK.imprint') }}" target="_blank" rel="noopener">{{ t('UI.imprint') }}</a>
                </li>
                <li class="block mb-2">
                  <a class="text-sm no-underline text-gray-800 hover:text-blue-500" href="{{ t('LINK.privacy') }}" target="_blank" rel="noopener">{{ t('UI.privacy') }}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/5 text-center sm:text-left mb-4 md:mb-0">
            <h2 class="text-sm uppercase text-gray-700 font-semibold mb-2">{{t('UI.links')}}</h2>
            <nav>
              <ul class="list-none p-0 text-center sm:text-left">
                <li class="block mb-2">
                  <a class="text-sm no-underline text-gray-800 hover:text-blue-500" href="https://et.training/content-library" target="_blank" rel="noopener">{{ t('UI.content_library') }}</a>
                </li>
                <li class="block mb-2">
                  <a class="text-sm no-underline text-gray-800 hover:text-blue-500" [href]="t('LINK.single_license')" target="_blank" rel="noopener">{{ t('UI.subscription') }}</a>
                </li>
                <li class="block mb-2">
                  <a class="text-sm no-underline text-gray-800 hover:text-blue-500" [href]="t('LINK.company_access')" target="_blank" rel="noopener">{{ t('UI.company_access') }}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/5 text-center sm:text-left mb-4 md:mb-0">
          </div>
          <div class="w-full sm:w-1/2 md:w-2/5 flex flex-row flex-wrap justify-center sm:justify-end">

            <div class="w-full flex flex-wrap justify-center sm:justify-end">
              <a routerLink="/">
                <img class="w-full h-auto max-w-logoFooter block" [src]="'assets/logo/espresso-tutorials-logo.svg'" alt="Espresso Tutorials GmbH">
              </a>
              <div class="mt-2 w-full">
                <ul class="flex flex-row justify-center sm:justify-end items-center">
                  <li class="mr-2">
                    <a href="https://www.facebook.com/EspressoTutorials/" target="_blank" rel="noopener" class="text-gray-600 hover:text-facebook-500 duration-200 ease-in-out transition-colors">
                      <fa-icon class="text-2xl" [icon]="faFacebook"></fa-icon>
                    </a>
                  </li>
                  <li class="mr-2">
                    <a href="https://www.instagram.com/espresso_tutorials/" target="_blank" rel="noopener" class="text-gray-600 hover:text-facebook-500 duration-200 ease-in-out transition-colors">
                      <fa-icon class="text-2xl" [icon]="faInstagram"></fa-icon>
                    </a>
                  </li>
                  <li class="mr-2">
                    <a href="https://www.linkedin.com/company/espresso-tutorials-gmbh/" target="_blank" rel="noopener" class="text-gray-600 hover:text-linkedIn-500 duration-200 ease-in-out transition-colors">
                      <fa-icon class="text-2xl" [icon]="faLinkedIn"></fa-icon>
                    </a>
                  </li>
                  <li class="mr-2">
                    <a href="https://twitter.com/info_tutorials" target="_blank" rel="noopener" class="text-gray-600 hover:text-twitter-500 duration-200 ease-in-out transition-colors">
                      <fa-icon class="text-2xl" [icon]="faTwitter"></fa-icon>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/user/EspressoTutorials" target="_blank" rel="noopener" class="text-gray-600 hover:text-red-400 duration-200 ease-in-out transition-colors">
                      <fa-icon class="text-2xl" [icon]="faYoutube"></fa-icon>
                    </a>
                  </li>
                </ul>
              </div>

            </div>
            <p class="text-gray-800 text-xs w-full mt-2 text-center sm:text-right">
              © 2011 - {{actualYear}} Espresso Tutorials GmbH
            </p>

          </div>

        </div>
      </div>
    </div>

  </footer>
</ng-container>
