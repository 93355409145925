<ng-container *transloco="let t">
  <button [matMenuTriggerFor]="menu" aria-label="{{t('UI.select_language')}}" class="appearance-none block bg-transparent text-gray-700 text-sm focus:outline-none">
    <fa-icon class="block xl:hidden text-lg sm:text-2xl" [icon]="faLanguage"></fa-icon>
    <span class="hidden xl:block">{{t('UI.language')}}: <span class="text-blue-500">{{activeLanguage}}</span></span>
  </button>
  <mat-menu [class]="'py-1 px-4 mat-menu-panel--lang'" xPosition="before" #menu="matMenu">
    <ng-container>
      <nav class="grid grid-cols-1 p-4">
        <button lang="en" class="appearance-none bg-transparent border-0 text-sm font-semibold text-gray-700 hover:text-blue-500 flex flex-row justify-between items-center border-b border-gray-300 border-solid pb-3 group" (click)="select('en')">
          <span class="flex-1 text-left">{{t('UI.english')}}</span>
          <i class="w-6 h-6 rounded-full overflow-hidden ml-2 border border-gray-400 broder-solid shadow-none group-hover:shadow-lg transition-shadow ease-in-out duration-200">
            <img src="assets/images/flags/vereinigte-staaten.png" class="w-full h-full" alt="{{t('UI.english')}}">
          </i>
        </button>
        <button lang="de" class="appearance-none bg-transparent border-0 text-sm font-semibold text-gray-700 hover:text-blue-500 flex flex-row justify-between items-center border-b border-gray-300 border-solid py-3 group" (click)="select('de')">
          <span class="flex-1 text-left">{{t('UI.german')}}</span>
          <i class="w-6 h-6 rounded-full overflow-hidden ml-2 border border-gray-400 broder-solid shadow-none group-hover:shadow-lg transition-shadow ease-in-out duration-200">
            <img src="assets/images/flags/deutschland.png" class="w-full h-full" alt="{{t('UI.german')}}">
          </i>
        </button>
        <button lang="fr" class="appearance-none bg-transparent border-0 text-sm font-semibold text-gray-700 hover:text-blue-500 flex flex-row justify-between items-center border-b border-gray-300 border-solid py-3 group" (click)="select('fr')">
          <span class="flex-1 text-left">{{t('UI.french')}}</span>
          <i class="w-6 h-6 rounded-full overflow-hidden ml-2 border border-gray-400 broder-solid shadow-none group-hover:shadow-lg transition-shadow ease-in-out duration-200">
            <img src="assets/images/flags/frankreich.png" class="w-full h-full" alt="{{t('UI.french')}}">
          </i>
        </button>
        <button lang="es" class="appearance-none bg-transparent border-0 text-sm font-semibold text-gray-700 hover:text-blue-500 flex flex-row justify-between items-center border-b border-gray-300 border-solid py-3 group" (click)="select('es')">
          <span class="flex-1 text-left">{{t('UI.spanish')}}</span>
          <i class="w-6 h-6 rounded-full overflow-hidden ml-2 border border-gray-400 broder-solid shadow-none group-hover:shadow-lg transition-shadow ease-in-out duration-200">
            <img src="assets/images/flags/spanien.png" class="w-full h-full" alt="{{t('UI.spanish')}}">
          </i>
        </button>
      </nav>
    </ng-container>
  </mat-menu>
</ng-container>
