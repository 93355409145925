import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule } from '@angular/material/menu';
import { SharedAllModule } from '../shareAllmodule';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoginComponent } from './login/login.component';
import { LogoComponent } from './logo/logo.component';
import { TranslocoRootModule } from '../../transloco/transloco-root.module';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { CtaLargeComponent } from './cta-large/cta-large.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionGroupComponent } from './accordion-group/accordion-group.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PartnerListComponent } from './partner-list/partner-list.component';
import { SharedPipesModule } from '../pipes/sharePipesmodule';
import { LoadingComponent } from './loading/loading.component';
import { ListLoadingComponent } from '../front/list-loading/list-loading.component';
import { ProfileButtonComponent } from './profile-button/profile-button.component';
import { SwiperModule } from 'swiper/angular';
import { DialogContestComponent } from './dialog-contest/dialog-contest.component';

const components = [
  ShellComponent,
  HeaderComponent,
  FooterComponent,
  LoginComponent,
  LogoComponent,
  LanguageSwitcherComponent,
  CtaLargeComponent,
  AccordionComponent,
  AccordionGroupComponent,
  PartnerListComponent,
  LoadingComponent,
  ListLoadingComponent,
  ProfileButtonComponent,
  DialogContestComponent
];

const modules = [
  CommonModule,
  RouterModule,
  MatMenuModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatDialogModule,
  FontAwesomeModule,
  SharedAllModule,
  TranslocoRootModule,
  SharedPipesModule,
  SwiperModule
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        ...modules
    ],
    exports: [
        ...components,
        ...modules,
    ],
    providers: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class SharedModule {}
