import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { TranslocoService } from '@ngneat/transloco';
import { Attachment } from '../../interfaces/attachment';
import { Router } from '@angular/router';
import { SlugifyPipe } from '../../pipes/slugify.pipe';

@Component({
  selector: 'app-dialog-contest',
  templateUrl: './dialog-contest.component.html',
  styleUrls: ['./dialog-contest.component.scss']
})
export class DialogContestComponent implements OnInit {

  private browserLanguage: string;

  public logoUrl: string;
  public headline: string;
  public button: string;
  public browserText: string;

  public attachment: Attachment;
  public title: string;
  public claim: string;
  public description: string;
  public sponsor: any;
  public conf: any;

  faClose = faTimes;

  constructor(
    public dialogRef: MatDialogRef<DialogContestComponent>,
    private translate: TranslocoService,
    @Inject(MAT_DIALOG_DATA) data,
    public router: Router,
    public sugify: SlugifyPipe
  ) {
    this.attachment = data.image;
    this.title = data.title;
    this.description = data.description;
    this.claim = data.claim;
    this.sponsor = data.sponsor;
    this.conf = data.conf;
  }

  ngOnInit(): void {
    this.initLanguage();
    this.logoUrl = 'assets/logo/espresso-tutorials-logo.png';
  }

  onClose(start?: boolean): void {
    if (start) {
      this.goToSponsor();
    } else {
      this.dialogRef.close();
    }
  }

  initLanguage(): void {
    this.browserLanguage = this.translate.getActiveLang();
    if (this.browserLanguage === 'de') {
      this.button = 'Jetzt loslegen und teilnehmen';
    }  else {
      this.button = 'Start now and participate';
    }
  }

  goToSponsor(): void {
    this.router.navigateByUrl(`/conference/${this.conf}/partner/${this.sponsor.id}/overview/${this.sugify.transform(this.sponsor.name)}`).then(
      () => {
        this.dialogRef.close(true);
      }
    )
  }

}
