<div class="mypanel">
  <div class="bg-white transition duration-500 ease-in-out transform hover:-translate-y-1 shadow-lg hover:shadow-xl cursor-pointer p-4 font-semibold text-gray-800 mb-4 flex flex-row flex-wrap items-center" (click)="toggle.emit()">
    <span class="flex-1">{{title}}</span>
    <i class="w-6 h-6 flex items-center justify-center transition duration-500 ease-in-out transform" [ngClass]="{'rotate-180': opened}">
      <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-down" class="w-6 h-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"></path></svg>
    </i>
  </div>
  <div class="text-gray-700" [ngClass]="{'hidden': !opened}">
    <ng-content></ng-content>
  </div>
</div>
