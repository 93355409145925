import { Component, OnDestroy, OnInit } from '@angular/core';
import { faSignIn, faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth-service.service';
import { SuccessHandlerService } from '../../services/success-handler.service';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { SessionStore } from '../../session/state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  faSignIn = faSignIn;
  faSignOut = faSignOut;

  constructor(
    private router: Router,
    private authService: AuthService,
    private successHandler: SuccessHandlerService,
    private translate: TranslocoService,
    private authStore: SessionStore,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async goLogin(): Promise<any> {
    await this.router.navigateByUrl('/login');
  }

  logout(): any {
    this.subscription = this.authService.logout().subscribe(
      () => {
        this.authStore.logout();
        localStorage.clear();
        this.router.navigateByUrl('/').then();
        this.successHandler.successHandler(this.translate.translate('MESSAGES.sign_out'), 'Successfully sign out');
      },
      () => {
        this.authStore.logout();
        localStorage.clear();
        this.router.navigateByUrl('/').then();
        this.successHandler.successHandler(this.translate.translate('MESSAGES.sign_out'), 'Successfully sign out');
      }
    );
  }

}
