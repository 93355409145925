import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFavorite'
})
export class IsFavoritePipe implements PipeTransform {

  transform(value: unknown, favs: any, check: boolean): unknown {
    if (!check) {
      return false;
    } else {
      if (favs.indexOf(value) !== -1) {
        return false;
      } else {
        return true;
      }
    }
  }

}

