const SESSION_KEY = 'sessionInfoday';

export function getSession(): any {
  const session = localStorage.getItem(SESSION_KEY);
  return session ? JSON.parse(session) : {};
}

export function saveSession( session ): any {
  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
}

export function clearSession(): any {
  localStorage.removeItem(SESSION_KEY);
}
