import { Component, OnInit } from '@angular/core';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { SessionQuery } from '../../session/state';

@Component({
  selector: 'app-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.scss']
})
export class ProfileButtonComponent implements OnInit {

  faUser = faUser;
  public imageUrl: string;

  constructor(
    private sessionQuery: SessionQuery
  ) { }

  ngOnInit(): void {
    this.imageUrl =  this.sessionQuery.getImage();
  }

}
