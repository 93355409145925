import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Language } from '../../interfaces/language';
import { Injectable } from '@angular/core';

export interface LanguageState extends EntityState<Language> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'language' })
export class LanguageStore extends EntityStore<LanguageState, Language> {
  constructor() {
    super();
  }
}
