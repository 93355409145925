import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  constructor(
    private translate: TranslocoService
  ) {
  }

  transform(value: unknown, ...args: unknown[]): unknown {

    switch (value) {
      case 'de':
        return this.translate.translate('UI.german');
      case 'es':
        return this.translate.translate('UI.spanish');
      case 'fr':
        return this.translate.translate('UI.french');
      case 'pt':
        return this.translate.translate('UI.portuguese');
      case 'ja':
        return this.translate.translate('UI.japanese');
      default:
        return this.translate.translate('UI.english');
    }

  }

}
