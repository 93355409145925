import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { CookieService } from 'ngx-cookie-service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedPipesModule } from './pipes/sharePipesmodule';
import { ShowIfLoggedInDirective } from './session/show-if-logged-in.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AdBlockerService } from './services/ad-blocker.service';
import { DialogAdBlockerComponent } from './global/dialog-adBlocker/dialog-adBlocker.component';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { AccessControlDirective } from './directives/access-control.directive';

const components = [
  DialogAdBlockerComponent,
  AccessControlDirective
];

const modules = [
  ReactiveFormsModule,
  TranslocoRootModule,
  TranslocoLocaleModule,
  FontAwesomeModule,
  MatTooltipModule,
  SharedPipesModule,
  NgxSkeletonLoaderModule
];

const services = [
  CookieService,
  AdBlockerService
];

const directives = [
  ShowIfLoggedInDirective
];

@NgModule({
    declarations: [
        ...components,
        ...directives
    ],
    imports: [
        ...modules
    ],
    exports: [
        ...components,
        ...modules,
        ...directives
    ],
    providers: [
        ...services
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class SharedAllModule {}
