<div class="relative" mat-dialog-content>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
    <div class="">
      <h2 class="text-blue-500 font-bold text-2xl mb-4">{{title}}</h2>
      <div class="wysiwyg" [innerHTML]="description"></div>
      <p class="text-gray-650 text-xs mt-4">{{claim}}</p>
      <div mat-dialog-actions>
        <button class="w-full appearance-none block bg-blue-500 hover:bg-orange-500 text-white text-sm font-semibold p-2 rounded shadow mt-auto transition-all transform duration-700 ease-in-out" (click)="onClose(true)">
          {{button}}
        </button>
      </div>
    </div>
    <div class="">
      <img class="w-full h-auto block" [src]="attachment.url" [alt]="title">
    </div>
  </div>
</div>
