import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isRated'
})
export class IsRatedPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    // @ts-ignore
    const index = args[0].indexOf(value);
    if (index > -1) {
      return 'bg-gray-500';
    }

    return '';
  }

}
