import { Pipe, PipeTransform } from '@angular/core';
import { format, differenceInDays, parseISO } from 'date-fns';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'dateDifference'
})
export class DateDifferencePipe implements PipeTransform {

  constructor(
    private translate: TranslocoService
  ) {
  }

  transform(date: any): unknown {

    const todayDate = format(new Date(), 'MM/dd/yyyy');

    const iso = parseISO(date);
    const eventDate = format(iso, 'MM/dd/yyyy');

    let differenceTime = differenceInDays(
      new Date(eventDate),
      new Date(todayDate),
    );

    let difference = '';
    if (this.translate.getActiveLang() === 'de') {
      if (differenceTime > 1) {
        difference = `Noch ${differenceTime} Tage`;
      } else {
        difference = `Noch ${differenceTime} Tag`;
      }
    } else {
      if (differenceTime > 1) {
        difference = `${differenceTime} days to go`;
      } else {
        difference = `${differenceTime} day to go`;
      }
    }

    let style = '';
    if (differenceTime >= 14) {
      style = 'h-2 w-3/4 bg-green-500 rounded-full';
    } else if (differenceTime >= 7) {
      style = 'h-2 w-2/4 bg-blue-500 rounded-full';
    } else if (differenceTime <= 0) {
      differenceTime = 0;
      style = 'h-2 w-0 bg-blue-500 rounded-full';
    } else {
      style = 'h-2 w-1/4 bg-orange-500 rounded-full';
    }

    const diff = {
      style,
      differenceTime,
      difference
    };

    // console.log(diff);

    return diff;
  }

}
