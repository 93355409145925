import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogAdBlockerComponent } from '../global/dialog-adBlocker/dialog-adBlocker.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AdBlockerService implements OnDestroy {

  private subscription: Subscription;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnDestroy(): void {}

  checkAdBlocker(): void {
    const flaggedURL = 'https://adblockanalytics.com';

    if (window.fetch) {
      const request = new Request(flaggedURL, {
        method: 'HEAD',
        mode: 'no-cors',
      });
      fetch(request)
        .then(() => {
        })
        .catch(() => {
          this.showAdBlockerNote();
        });
    } else {
      const http = new XMLHttpRequest();
      http.open('HEAD', flaggedURL, false);

      try {
        http.send();
      } catch (err) {
        this.showAdBlockerNote();
      }
    }
  }

  showAdBlockerNote(): void {
    const dialogRef = this.dialog.open(DialogAdBlockerComponent, {
      width: '300%',
      maxWidth: '400px'
    });
  }
}
