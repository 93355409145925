<div class="relative" mat-dialog-content>
  <button class="appearance-none w-8 h-8 flex items-center justify-center text-sm text-gray-600 absolute right-0 top-0 -mt-5 -mr-5 outline-none focus:outline-none z-10" (click)="onClose()">
    <fa-icon [icon]="faClose"></fa-icon>
  </button>
  <h2 class="font-bold text-gray-800 text-center mt-2 mb-4">{{headline}}</h2>
  <div class="my-2 text-sm">
    {{browserText}}
  </div>
</div>
<div mat-dialog-actions>
  <button class="w-full appearance-none block bg-blue-500 hover:bg-orange-500 text-white text-xs p-2 rounded shadow mt-auto transition-all transform duration-700 ease-in-out" (click)="onClose()">
    {{btn}}
  </button>
</div>
