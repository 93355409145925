import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionQuery } from './state';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[showIfLoggedIn]' })
export class ShowIfLoggedInDirective implements OnInit, OnDestroy {
  subscription: Subscription;
  @Input() showIfLoggedIn: boolean;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authQuery: SessionQuery
  ) {}

  ngOnInit(): void {
    this.subscription = this.authQuery.isLoggedIn$.subscribe(isLoggedIn => {
      this.viewContainer.clear();
      if (isLoggedIn) {
        if (this.showIfLoggedIn) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      } else {
        if (this.showIfLoggedIn) {
          this.viewContainer.clear();
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
