import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { SlugifyPipe } from './slugify.pipe';
import { DateDifferencePipe } from './date-difference.pipe';
import { SecureFilePipe } from './secure-file.pipe';
import { MimeTypeFallbackPipe } from './mime-type-fallback.pipe';
import { SaferPipe } from './safe-url.pipe';
import { LanguagePipe } from './language.pipe';
import { CountryPipe } from './country.pipe';
import { SessionIsOverPipe } from './session-is-over.pipe';
import { IsFavoritePipe } from './is-favorite.pipe';
import { SapConnectionTypePipe } from './sap-connection-type.pipe';
import { IsRatedPipe } from './is-rated.pipe';
import { MarkedPipe } from './marked.pipe';


const pipes = [
  SlugifyPipe,
  DateDifferencePipe,
  SecureFilePipe,
  MimeTypeFallbackPipe,
  SaferPipe,
  LanguagePipe,
  CountryPipe,
  SessionIsOverPipe,
  IsFavoritePipe,
  SapConnectionTypePipe,
  IsRatedPipe,
  MarkedPipe
];

@NgModule({
    declarations: [
        ...pipes
    ],
    imports: [],
    exports: [
        ...pipes
    ],
    providers: [
        ...pipes,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class SharedPipesModule {}
